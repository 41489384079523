import { thunk } from "easy-peasy";
import { ENDPOINTS_SUPPORT } from "../../constants/endpoints";
import { aloApi } from "../../core/alo-api";

export const modelReleaseNotes = {
  /**
   * Action: Call api to get release notes
   */
  getReleaseNotes: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS_SUPPORT.RELEASE_NOTES}?pagination[page]=1&pagination[pageSize]=100`;
      const res = await aloApi.get(url).then((res) => res.data);

      return res;
    } catch (err) {
      console.error(err);
    } finally {
    }
  }),

  /**
   * Action: Call api to get release notes
   */
  getChangeLogs: thunk(async (action, payload) => {
    try {
      const url = `${ENDPOINTS_SUPPORT.RELEASE_NOTES_CHANGE_LOGS}?filters[studio_product][Name][$eq]=${payload?.Name}&pagination[page]=${payload?.page}&pagination[pageSize]=${payload?.limit}&sort=id:desc`;
      const res = await aloApi.get(url).then((res) => res.data);

      return res;
    } catch (err) {
      console.error(err);
    } finally {
    }
  }),
};
