import { lazy } from "react";

const PricingPage = lazy(() => import("./pricing"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  // {
  //   path: "/pricing",
  //   public: true,
  //   component: PricingPage,
  // },
];
