import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useStoreActions } from "easy-peasy";

import { getStrapiMedia } from "../../common/utils";
import Layout from "../../common/components/layout";
import Pagination from "../../common/components/pagination/pagination";

import "./release-notes.scss";

const gfm = require("remark-gfm");

const ReleaseNotesPage = () => {
  const getReleaseNotes = useStoreActions(
    (action) => action.releaseNotes.getReleaseNotes
  );
  const getChangeLogs = useStoreActions(
    (action) => action.releaseNotes.getChangeLogs
  );

  // Component state
  const [releaseNoteList, setReleaseNoteList] = useState([]);
  const [query, setQuery] = useState({});
  const [changeLogList, setChangeLogList] = useState([]);

  /**
   * Get change log list
   */
  const getChangeLogList = async (params) => {
    if (!params) {
      return;
    }

    const newQuery = {
      tabIndex: params?.tabIndex || 0,
      page: params?.page || 1,
      limit: params?.limit || 10,
      Name: params?.Name,
    };

    const res2 = await getChangeLogs(newQuery);

    const newChangeLogs =
      Array.isArray(res2?.data) && res2?.data.length ? [...res2?.data] : [];

    newQuery.total = !isNaN(res2?.meta?.pagination?.total)
      ? res2?.meta?.pagination?.total
      : 0;

    setChangeLogList(newChangeLogs);
    setQuery(newQuery);
  };

  /**
   * Get release notes
   */
  useEffect(() => {
    (async () => {
      const res = await getReleaseNotes();

      const newReleaseNotes =
        Array.isArray(res?.data) && res?.data.length ? [...res?.data] : [];

      if (newReleaseNotes.length && newReleaseNotes[0]?.attributes?.Name) {
        getChangeLogList({
          tabIndex: 0,
          page: 1,
          limit: 10,
          Name: newReleaseNotes[0]?.attributes?.Name,
        });
      }

      setReleaseNoteList(newReleaseNotes);
    })();
  }, [getReleaseNotes, getChangeLogs]);

  /**
   * On change tab
   */
  const onChangeTab = (val, Name) => {
    getChangeLogList({
      ...query,
      tabIndex: val,
      Name,
      page: 1,
    });
  };

  /**
   * On change pagination
   */
  const onChangePagination = (params) => {
    getChangeLogList({
      ...query,
      page: params?.page,
    });
  };

  return (
    <Layout hiddenStarted>
      <div className="release-notes-page">
        <h1>akaAT Release Notes</h1>

        <div className="release-notes-content">
          {Array.isArray(releaseNoteList) && releaseNoteList.length > 0 && (
            <div className="box-tabs">
              <div className="tabs-nav">
                {releaseNoteList.map((item, index) => {
                  return (
                    <button
                      key={item?.id}
                      className={`btn-tab ${
                        query?.tabIndex === index ? "active" : ""
                      }`}
                      onClick={() => onChangeTab(index, item?.attributes?.Name)}
                    >
                      {item?.attributes?.Name || ""}
                    </button>
                  );
                })}
              </div>

              <div className="tabs-content">
                <div className="box-change-logs">
                  {Array.isArray(changeLogList) && changeLogList.length > 0 && (
                    <>
                      <div className="list">
                        {changeLogList.map((item) => {
                          return (
                            <div className="item">
                              <h4 className="title-item">
                                {item?.attributes?.version || ""}
                              </h4>

                              <div className="box-content">
                                <ReactMarkdown
                                  children={item?.attributes?.changelog}
                                  remarkPlugins={[gfm]}
                                  linkTarget="_blank"
                                  components={{
                                    img: ({ node, ...props }) => (
                                      <img
                                        src={getStrapiMedia(props.src)}
                                        alt={props.alt}
                                      />
                                    ),
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <Pagination
                        currentPage={query?.page - 1}
                        numberPerPage={query?.limit}
                        totalValue={query?.total}
                        getPage={(page) => onChangePagination({ page })}
                        onPageChange={(page) => onChangePagination({ page })}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReleaseNotesPage;
