import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useStoreActions, useStoreState } from "easy-peasy";

import {
  IconDownload,
  IconGlobal,
  IconLogoDeviceFarm,
  IconLogoStudio,
  IconLogoTestEngine,
  IconLogoTestManagement,
  IconMagnifier,
  IconNavEvent,
  IconNavInsights,
  IconNavSuccessStories,
} from "../../../assets/icons/icon";
import { BASE_URL_FORUM } from "../../../constants/endpoints";
import { Context } from "../wrapperIntl";
import { ProfileDefaultAvatar } from "../../../assets/images";

const NavBars = () => {
  const { keycloak } = useKeycloak();
  const userInfo = useStoreState((state) => state.global?.userInfo);

  const getUserInfo = useStoreActions((action) => action.global.fetchUserInfo);

  useEffect(() => {
    if (keycloak?.authenticated) {
      getUserInfo();
    }
  }, [keycloak?.authenticated]);

  const context = useContext(Context);
  const { formatMessage } = useIntl();
  return (
    <>
      {/* bar */}
      <div className="nav-left me-auto mb-2 mb-lg-0 d-none d-lg-block">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="#" className="nav-link">
              {formatMessage({ id: "menu.product" })}
            </Link>
            <div className="has-submenu-2">
              <div className="nav-submenu-2">
                <section className="nav-submenu-left">
                  <p className="divider-20">FEATURED PRODUCTS</p>
                  <Link to="/aka-studio">
                    <article className="submenu-item-product divider-16">
                      <div className="product-icon">
                        <IconLogoStudio />
                      </div>

                      <div className="product-description">
                        <p>Studio</p>
                        <span>
                          Free low-code automation testing tool for everyone.
                        </span>
                      </div>
                    </article>
                  </Link>

                  <Link to="/aka-test-engine">
                    <article className="submenu-item-product divider-16">
                      <div className="product-icon">
                        <IconLogoTestEngine />
                      </div>

                      <div className="product-description">
                        <p>Test Engine</p>
                        <span>
                          The shortcut to effortless automation test
                          orchestration
                        </span>
                      </div>
                    </article>
                  </Link>

                  {/* <article className="submenu-item-product divider-16">
                    <div className="product-icon">
                      <img
                        src="https://f.hubspotusercontent20.net/hubfs/2465122/Katalon_December2021/Images/Katalon-TestCloud.svg"
                        alt="logo"
                      />
                    </div>

                    <div className="product-description">
                      <Link to="/aka-studio">Runtime Engine</Link>
                      <p>Support text</p>
                    </div>
                  </article> */}
                  <Link to="/aka-test-management">
                    <article className="submenu-item-product divider-16">
                      <div className="product-icon">
                        <IconLogoTestManagement />
                      </div>

                      <div className="product-description">
                        <p>Test Management</p>
                        <span>
                          Work smarter together with akaAT collaborative test
                          management tool
                        </span>
                      </div>
                    </article>
                  </Link>

                  <Link to="/aka-device-farm">
                    <article className="submenu-item-product divider-16">
                      <div className="product-icon">
                        <IconLogoDeviceFarm />
                      </div>

                      <div className="product-description">
                        <p>Device Farm</p>
                        <span>
                          Mobile remote testing is made easy with online devices
                          from akaAT
                        </span>
                      </div>
                    </article>
                  </Link>
                </section>
                {/* <section className="nav-submenu-right">
                  <p className="divider-20">SOLUTION</p>
                  <div className="submenu-solution-wrap divider-32">
                    <article className="solution-description">
                      <p>Web Testing</p>
                      <span>A robust solution for end-to-end web testing</span>
                    </article>
                    <article className="solution-description">
                      <p>API Testing</p>
                      <span>API automation for all tester and developers</span>
                    </article>
                  </div>
                  <div className="submenu-solution-wrap divider-32">
                    <article className="solution-description">
                      <p>Mobile Testing</p>
                      <span>Test mobile web and iOS/Android apps</span>
                    </article>
                    <article className="solution-description">
                      <p>Destop Testing</p>
                      <span>A flexible solution for all destop apps</span>
                    </article>
                  </div>
                  <div className="submenu-solution-wrap divider-32">
                    <article className="solution-description">
                      <p>Integrations</p>
                      <span>
                        Native integrations with popular ALM, CI/CD and DevOps
                        tools teams use
                      </span>
                    </article>
                    <article className="solution-description">
                      <p>Methodologies</p>
                      <span>
                        From data-driven, cross-browser to BDD testing, and more
                      </span>
                    </article>
                  </div>
                </section> */}
                {/* adsdasdasd */}
              </div>
            </div>
          </li>
          {/* <li className="nav-item">
            <Link to="/pricing" className="nav-link">
              {formatMessage({ id: "menu.pricing" })}
            </Link>
          </li> */}

          {/* <Link to="/contact-us" className="nav-link ">
                    {formatMessage({ id: "menu.pricing" })}Contact Us
                    </Link> */}
          <li className="nav-item">
            <Link to="#" className="nav-link">
              {formatMessage({ id: "menu.resources" })}
            </Link>
            <div className="has-submenu">
              <div className="nav-submenu">
                <Link to="/download">
                  <IconDownload
                    style={{
                      verticalAlign: "bottom",
                      marginRight: "4px",
                    }}
                  />
                  Download
                </Link>
                <a
                  href={`https://docs.akaat.com`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconMagnifier
                    style={{
                      marginRight: "4px",
                    }}
                  />
                  Guideline
                </a>
                <Link to="/insights">
                  <IconNavInsights
                    style={{
                      verticalAlign: "bottom",
                      marginRight: "4px",
                    }}
                  />
                  Blog & News
                </Link>
                <Link to="/events">
                  <IconNavEvent
                    style={{
                      verticalAlign: "bottom",
                      marginRight: "4px",
                    }}
                  />
                  Event
                </Link>
                <Link to="/success-stories">
                  <IconNavSuccessStories
                    style={{
                      verticalAlign: "bottom",
                      marginRight: "4px",
                    }}
                  />
                  Success stories
                </Link>
                <a
                  href={`https://forum.akaat.com`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <IconGlobal
                    style={{
                      marginRight: "8px",
                      marginLeft: "2px",
                    }}
                  />
                  Community
                </a>
              </div>
            </div>
            {/* <div className="handelShowNavMobile"> </div> */}
            {/* <a href="#">Success stories</a>
                      <a href="#">Support</a> */}
          </li>

          <li className="nav-item">
            <Link to="/about" className="nav-link">
              {formatMessage({ id: "menu.about" })}
            </Link>
          </li>
        </ul>
      </div>
      {/* value={language} onChange={changRouter} */}
      <div className="nav-right d-none d-lg-block">
        <Link to="/download">
          <button className="btn me-4">
            <IconDownload className="m-1" />
            Download
          </button>
        </Link>

        <a href={`${BASE_URL_FORUM}`} target={"_blank"} rel="noreferrer">
          <button className="btn me-4">
            <IconGlobal className="me-1" />
            Join our Community
          </button>
        </a>

        {/* <a
          href={`#`}
          target={"_blank"}
          rel="noreferrer"
          style={{ textDecoration: "none", color: "#101828" }}
          className="ms-2 me-4"
        >
          <span className="">Log in</span>
          </a>*/}

        {keycloak?.authenticated && (
          <div className="current-user">
            <img
              alt="avatar user"
              width="40"
              height="40"
              src={`${userInfo?.attributes?.avatar || ProfileDefaultAvatar}`}
            />
            <div className="has-user-info">
              <div className="user-submenu">
                <Link to="/profile">User info</Link>
                <Link to="#" onClick={() => keycloak?.logout()}>
                  Log out
                </Link>
              </div>
            </div>
          </div>
        )}

        {!keycloak?.authenticated && (
          <button className="btn me-4" onClick={() => keycloak?.login()}>
            Log in
          </button>
        )}

        {/* <div className="divider-vertical">&nbsp;</div> */}
        {/* <select
          value={context.locale}
          onChange={context.selectLanguage}
          style={{ verticalAlign: "middle", border: "none" }}
        >
          <option value="en">EN</option>
          <option value="vi">VI</option>
        </select> */}
      </div>
      {/* end bar */}
    </>
  );
};

export default NavBars;
