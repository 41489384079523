import React, { memo } from "react";
import { useMemo } from "react";
import ReactPaginate from "react-paginate";

import "./pagination.scss";

export const Pagination = ({
  totalValue,
  numberPerPage,
  currentPage,
  getPage,
  getSize,
}) => {
  const pageCount = useMemo(() => {
    return Math.ceil(totalValue / numberPerPage);
  }, [totalValue, numberPerPage]);

  const changePage = (e) => {
    document.body.scrollTop = document.documentElement.scrollTop = 700;
    getPage(e.selected + 1);
  };

  return (
    <div className="nav__paging">
      <div className="nav-paging">
        <ReactPaginate
          previousLabel=""
          nextLabel=""
          containerClassName="nav__paging__list"
          previousClassName="tabs-page-arrow"
          nextClassName="tabs-page-arrow"
          previousLinkClassName="arrow left"
          nextLinkClassName="arrow right"
          activeClassName="tabs-page-active"
          pageCount={pageCount}
          onPageChange={changePage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default memo(Pagination);
